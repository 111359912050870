<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>结算中心</a-breadcrumb-item>
    <a-breadcrumb-item>我的结算单</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 头部功能条 -->
  <div class="head-tool-view">
    <!-- 结算单操作 -->
    <div class="tool-operate">
      <div class="tool-operate-item">
        <div class="title">总分成(不含当日)</div>
        <div class="desc">{{ $pub.KEEP_NUMBER_DECIMAL((detail.total_amount || 0) / 100, 2, false, true) }}</div>
      </div>
      <div class="tool-operate-line"></div>
      <div class="tool-operate-item">
        <div class="title">已结算金额</div>
        <div class="desc">{{ $pub.KEEP_NUMBER_DECIMAL((detail.cash_amount || 0) / 100, 2, false, true) }}</div>
      </div>
      <div class="tool-operate-line"></div>
      <div class="tool-operate-item">
        <div class="title">可结算余额</div>
        <div class="desc">{{ $pub.KEEP_NUMBER_DECIMAL((detail.no_cash_amount || 0) / 100, 2, false, true) }}</div>
      </div>
      <div class="tool-operate-item">
        <a-button type="primary" @click="touchSettlement">发起结算</a-button>
      </div>
    </div>
    <!-- 结算说明 -->
    <div class="settlement-desc">
      <div class="settlement-desc-row">结算说明：</div>
      <div class="settlement-desc-row" v-if="detail.cash_cycle === 1 || !detail.cash_cycle">1、结算周期为日结，当天可结算昨天（包含）之前的分成金额</div>
      <div class="settlement-desc-row" v-else-if="detail.cash_cycle === 2">1、结算周期为周结，当天可结算上周日（包含）之前的分成金额。</div>
      <div class="settlement-desc-row" v-else-if="detail.cash_cycle === 3">1、结算周期为月结，当天可结算上个月最后一天（包含）之前的分成金额。</div>
      <div class="settlement-desc-row">2、创建结算单后，请尽快将发票快递至：上海市徐汇区桂林路396号3号楼5楼；袁悦；13180815742。发票信息需跟签约公司主体保持一致，发票类型为增值税专用发票。<a @click="touchInvoicingInfo">查看开票信息</a></div>
      <div class="settlement-desc-row">3、我们收到发票后，若发票信息核对无误，将在3个工作日内打款，请关注下方结算单中的进度。</div>
    </div>
    <!-- 分割线 -->
    <div class="head-tool-space"></div>
  </div>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">我的结算单</span>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
       <!-- 进度 -->
       <template v-if="column.key === 'status'">
        {{ (SettlementResults.find(item => item.value === record[column.key]) || {}).text }}
      </template>
      <!-- 金额 -->
      <template v-if="column.key === 'cash_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 周期 -->
      <template v-if="column.key === 'cycle'">
        {{ `${record.start_time}至${record.end_time}` }}
      </template>
    </template>
  </a-table>
  <!-- 开票信息 -->
  <InvoicingInfoModal ref="RefInvoicingInfoModal"></InvoicingInfoModal>
  <!-- 发起结算 -->
  <InitiateSettlement ref="RefInitiateSettlement" @success="settlementSuccess"></InitiateSettlement>
</template>

<script setup>
import { onBeforeMount, ref, reactive, createVNode } from 'vue'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { SettlementResults } from '@/utils/constantList'
import { cashProjectCash, callbackVirtualList, cashList } from '@/api/operate'
import InvoicingInfoModal from './components-settlement/InvoicingInfoModal'
import InitiateSettlement from './components-settlement/InitiateSettlement'

// 开票信息
let RefInvoicingInfoModal = ref(null)
// 发起结算
let RefInitiateSettlement = ref(null)
// 加载
let isLoading = ref(false)
// 账户详情
let detail = ref({})
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '提交日期',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '结算周期',
    dataIndex: 'cycle',
    key: 'cycle'
  },
  {
    title: '结算金额(元)',
    dataIndex: 'cash_amount',
    key: 'cash_amount'
  },
  {
    title: '结算进度',
    dataIndex: 'status',
    key: 'status'
  }
])
// 钩子函数
onBeforeMount (() => {
  // 获取账户信息
  getProjectCash()
  // 获取注册用户列表
  getList()
})

// 显示开票信息
function touchInvoicingInfo () {
  RefInvoicingInfoModal.value.showModal()
}

// 发起结算
function touchSettlement () {
  RefInitiateSettlement.value.showDrawer()
}

// 结算成功
function settlementSuccess () {
  getProjectCash()
  getList()
}

// 获取账户信息
function getProjectCash () {
  cashProjectCash().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      detail.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取注册用户列表
function getList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  cashList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getList()
}
</script>

<style lang="less" scoped>
.head-tool-view {
  padding-top: 30px;
}
.head-tool-space {
  margin-top: 30px;
  height: 30px;
  background-color: #F0F2F5;
  margin-left: -24px;
  margin-right: -24px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title {
  font-weight: 400;
  font-size: 18px;
}
.operation-item {
  margin-right: 8px;
}
.tool-operate {
  display: flex;
  align-items: center;
  .tool-operate-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.43);
    }
    .desc {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .tool-operate-line {
    width: 1px;
    height: 40px;
    background-color: #E9E9E9;
  }
}
.settlement-desc {
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #9A9A9A;
  line-height: 24px;
}
</style>