<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="发起结算"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="content">
      <div class="content-item">
        <span class="title">结算周期：</span>
        <span class="desc">{{ detail.start_time }}至{{ detail.end_time }}</span>
      </div>
      <div class="content-item">
        <span class="title">结算金额：</span>
        <span class="desc">{{ $pub.KEEP_NUMBER_DECIMAL((detail.cash_amount || 0) / 100, 2, false, true) }}元</span>
      </div>
      <div class="content-item">
        <span class="title">收款主体：</span>
        <span class="desc">{{ detail.company_name }}</span>
      </div>
      <div class="content-item">
        <span class="title">收款账号：</span>
        <span class="desc">{{ detail.bank_account }}</span>
      </div>
      <div class="content-item">
        <span class="title">开户行：</span>
        <span class="desc">{{ detail.bank_branch }}</span>
      </div>
    </div>
    <!-- 尾部 -->
    <template #footer>
      <div style="display: flex; align-items: center; justify-content: center;">
        <a-checkbox v-model:checked="checked">我对本次结算的周期与金额确认无误</a-checkbox>
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-tooltip v-if="!checked">
          <template #title>先勾选左侧确认信息</template>
          <a-button type="primary" :disabled="!checked">确定</a-button>
        </a-tooltip>
        <a-button type="primary" @click="touchSubmit" v-else>确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { ref, reactive, defineExpose } from 'vue'
import { cashBeginCash, cashCashSave } from '@/api/operate'

const emits = defineEmits(['success'])
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 确认
let checked = ref(false)

// 打开抽屉
function showDrawer () {
  // 获取剧集列表
  getDetail()
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 获取列表
function getDetail () {
  checked.value = false
  isLoading.value = true
  cashBeginCash().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      detail.value = data
      visible.value = true
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 提交
function touchSubmit () {
  isLoading.value = true
  cashCashSave().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('结算单已提交')
      emits('success')
      onClose()
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.content-item {
  line-height: 40px;
  .title {
    font-size: 14px;
  }
  .desc {
    font-size: 14px;
    color: #5A5A5A;
  }
}
</style>